// Bootstrap variable overrides
@import "variables";

// Bootstrap
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/utilities/api";

// Utilities
@import "utilities/text-color";
// Tools
@import '@mrfrontend/itcss-tools/scss/sizing';
@import '@mrfrontend/itcss-tools/scss/spacing';

// Boostrap reset overrides
@import "reset";

// Global (custom) styles
@import "custom";

// Components
@import "components/grid-controls";
@import "components/no-subscript-mat-form-field";
@import "components/progress-bar-container";
@import "components/tinymce";

.mat-orange {
  color: #fff !important;
  stroke: #fff !important;
}
