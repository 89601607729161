html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

table {
  width: 100%;
}

strong {
  font-weight: 500;
}

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}

.page-header {
  .button-back {
    -ms-flex: 0 0 40px;
    flex: 0 40px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;

    mat-icon {
      margin-top: 3px;
    }

    a, a:active, a:hover {
      color: $black;
    }
  }
  .title {

  }
}

// Global styles: Data Tables

.table-wrapper {
  position: relative;
  overflow: hidden;

  .table-progress-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .no-data {
    color: $gray-600;
    height: 96px;
    padding-left: 1rem;
  }
}

// Global styles: Forms

.form-label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #808080;
  font-weight: 500;

  @include media-breakpoint-down(md) {
    padding-bottom: 0;
  }
}

.form-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.form-section-title {
  font-size: 1.1rem;
}

.form-checkbox {
  padding-bottom: 1.34em;
}

.button-save {
  mat-icon {
    margin-left: 10px;
  }
  mat-progress-spinner circle, .mat-spinner circle {
    stroke: #fff;
  }
}

.mat-form-field-wrapper {
  margin-top: 0.3em;
}

// Global styles: Notifications (mat-snack-bar)

.notification-success {
  background: $green;
  color: white;
  white-space: pre-wrap;
}
.notification-success button {
  background-color: $green;
  color: white;
  border: none;
}
.notification-error {
  background: $red;
  color: white;
}
.notification-error button {
  background-color: $red;
  color: white !important;
  border: none;
}

// Global styles: dialog (mat-dialog)

.basic-dialog {
  min-width: 400px;

  .mat-dialog-content {
    line-height: 1rem;
    padding-bottom: 20px;
  }
}

// Global styles: Tabs

.tab-content-wrapper {
  padding-top: 28px;
  overflow: hidden;
}

.mdc-tab__content {
  letter-spacing: normal;
}

// Global styles: Card

.mat-mdc-card-avatar {
  width: unset !important;
  height: unset !important; // workaround after material update (should be properly fixed)
}
